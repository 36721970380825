import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import LogoBrand from "@svgs/logo_brand.svg"
import LogoEnter from "@svgs/logo_enter.svg"
import LogoBrandBg from "@svgs/logo_brand_bg.svg"

const ConceptPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainConcept'>
      {/* CONTENTS */}

      <ComMain
        ttl="CONCEPT"
        subttl="コンセプト"
        caption=""
      >
      </ComMain>


      <section className='sec-lead'>
        <div className="bg-logo bg-logo1">
          <LogoBrandBg
            alt=""
          />
        </div>

        <div className="c-content">

          <div className="lead-wrap">
            <div className="lead-box comFeed">

              <p className="lead">進化する小田原、<br />その中心に象徴となる住まいを。</p>
              <p className="ttl">小田原の<span>標</span>。</p>
              <p className="txt">小田原駅東口、栄町。<br className='_sp' />ここは小田原城“生粋”の城下町。<br />遙かなる歴史を歩み続け、<br className='_sp' />今も昔も市の中心であるこの地を選び、<br />「ル・サンク小田原栄町」は誕生します。<br />それは、歴史と伝統の価値観を深化させて､<br className='_sp' />次の時代へと繋いでいく標。<br />人々が行き交い、交流する街の象徴となり、<br />街並に溶け込んで地域とつながり、<br className='_sp' />街の魅力を高め資源になる住まいを目指します。<br />小田原の伝統の上に、新たな独創性を加味した「ル・サンク小田原栄町」。<br />小田原を真に愛する方にこそ届けたい、全65邸。</p>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-gallery'>
        <div className="gallery-wrap comFeed">
          <div className="gallery-box">
            <div className="flex">
              <div className="img-box img-box-1">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_1.jpg" alt="" /></div>
                <span>1</span>
              </div>
            </div>
            <div className="flex">
              <div className="img-box img-box-2">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_2.jpg" alt="" /></div>
                <span>2</span>
              </div>
              <div className="img-box img-box-3">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_3.jpg" alt="" /></div>
                <span>1</span>
              </div>
              <div className="img-box img-box-4">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_4.jpg" alt="" /></div>
                <span>1</span>
              </div>
            </div>
          </div>
          <div className="gallery-note">
            <p className="c-note">1.小田原城址公園（約390m／徒歩5分） <br className='_sp' />2.小田原駅東口・ミナカ小田原（約340m／徒歩5分）</p>
          </div>
        </div>

      </section>


      <section className='sec-symbol'>
        <div className="bg-logo bg-logo2">
          <LogoBrandBg
            alt=""
          />
        </div>
        <div className="bg-logo bg-logo3">
          <LogoBrandBg
            alt=""
          />
        </div>

        <div className="c-content">

          <div className="lead-wrap">
            <div className="ttl-box comFeed">
              <p className="subttl">Symbol</p>
              <p className="ttl">正統的な小田原らしさを象徴する<br />住まいの創造。</p>
            </div>
            <div className="lead-box comFeed">
              <p className='lead'>⽇本に古くから伝わる家紋〜<br />紋章上繪師 波戸場承⿓氏が<br className='_sp' />デザイン。</p>
            </div>
            <div className="flex-box comFeed">
              <div className="left">
                <div className="logo1"><LogoBrand /></div>
              </div>
              <div className="right">
                <p className="txt">水平ラインを基調に、『人』『入』を建物サイドの窓枠デザインの『コ』の形に組み合わせ、人入ると言う意味を持たせました。手摺の横桟と窓の縦ラインを途切れることなく続く永続性のある市松に組み、「永遠」、「子孫繁栄」、「発展拡大」といった意味を込めました。</p>
                <div className="logo2"><LogoEnter /></div>
              </div>
            </div>
          </div>

          <div className="profile-wrap comFeed">
            <div className="img-box">
              <div className="img"><StaticImage src="../assets/images/concept/profile.jpg" alt="" /></div>
              <p className="c-cap">参考写真</p>
            </div>

            <div className="flex-box">
              <p className="ttl">PROFILE</p>
              <div className="img-box">
                <StaticImage
                  src="../assets/images/concept/hatoba.jpg"
                  alt="波戸場承龍"
                />
              </div>
              <div className="txt-box">
                <p className="p3">着物に家紋を手で描き入れる紋章上繪師としての技術を継承する一方、家紋の魅力を新しい形で表現したいという想いで、2007年より家紋のアート作品を制作。家紋やロゴデザインの域を超えて、森羅万象を描き出す職人兼デザイナーとして、あらゆる分野のデザインに挑戦し続けている。</p>
                <p className="p1">京源三代目<br />紋章上繪師</p>
                <p className="p2">波戸場承龍<br /><span>SHORYU  HATOBA</span></p>
              </div>
            </div>

          </div>

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表示については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の情報は 2022年1月時点のものです。</p>
          </div>
        </div>


      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default ConceptPage
